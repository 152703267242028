import React, { createContext, useContext, useRef } from 'react';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';  // If you're using React Router for navigation

const IdleTimerContext = createContext();

export const useIdleTimerContext = () => useContext(IdleTimerContext);

export const IdleTimerProviderComponent = ({ children }) => {
    const navigate = useNavigate();  // If you're using React Router
  const idleTimerRef = useRef(null);

  const onIdle = () => {
    // Redirect to login page
    console.log("User is Idle 20 minute")
    localStorage.removeItem('token');
    localStorage.removeItem('superAdminId')
    navigate('/authentication/login');
  };

  return (
    <IdleTimerProvider
      ref={idleTimerRef}
      timeout={20 * 60 * 1000} // 20 minutes
      onIdle={onIdle}
    >
      <IdleTimerContext.Provider value={idleTimerRef}>
        {children}
      </IdleTimerContext.Provider>
    </IdleTimerProvider>
  );
};
