import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./protectedRoute/protectedRoute";
import Loader from "./layouts/Loader/Loader";
import Auth from "./Authentication/auth";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./index.scss";
import JavaLogs from "./components/Logs/JavaLogs";
import {IdleTimerProviderComponent} from '../src/idleTimer/IdleTimerContext'
// Dashboard
const Error404 = React.lazy(() =>
  import("./components/Custompages/Error1-404/Error-404")
);

window.process = {};

const Dashboard = React.lazy(() =>
  import("./components/Dashboard/AICCDashboard")
);

const App = React.lazy(() => import("./components/app"));
const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const ForgotPassword = React.lazy(() =>
  import("./Authentication/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./Authentication/ResetPassword")
);

// ManageAdminUser
const CreateUser = React.lazy(() =>
  import("./components/ManageAdminUser/CreateUser/CreateUser")
);
const ListUser = React.lazy(() =>
  import("./components/ManageAdminUser/ListUser/ListUser")
);

// ManageCompany
const ListCompany = React.lazy(() =>
  import("./components/ManageCompany/ListCompany/ListCompany")
);
const ViewCompany = React.lazy(() =>
  import("./components/ManageCompany/ViewCompany/ViewCompany")
);

// ViewComapny
const CreateCompany = React.lazy(() =>
  import("./components/ManageCompany/CreateNewCompany/CreateCompany")
);

// ManageSubcription
const ListActiveSubcription = React.lazy(() =>
  import(
    "./components/ManageSubcription/ListActiveSubcription/ListActiveSubcription"
  )
);

// Create New Subscription
const CreateNewSubcription = React.lazy(() =>
  import(
    "./components/ManageSubcription/CreateNewSubcription/CreateNewSubcription"
  )
);

// ManageSubcription
const ListSubscriptionPlan = React.lazy(() =>
  import(
    "./components/ManageSubscriptionPlan/ListSubscriptionPlan/ListSubscriptionPlan"
  )
);
const CreateNewSubscriptionPlan = React.lazy(() =>
  import(
    "./components/ManageSubscriptionPlan/CreateNewSubscriptionPlan/CreateNewSubscriptionPlan"
  )
);

// Subcription add-on plan
const ListSubscriptionAddOnPlan = React.lazy(() =>
  import("./components/SubscriptionAddOnPlan/ListSubscriptionAddOnPlan")
);
const CreateSubscriptionAddOnPlan = React.lazy(() =>
  import("./components/SubscriptionAddOnPlan/CreateSubscriptionAddOnPlan")
);

// Subcription add-on attribute
const ListSubscriptionAddOnAttribute = React.lazy(() =>
  import(
    "./components/SubscriptionAddOnAttributes/ListSubscriptionAddOnAttribute"
  )
);
const CreateSubscriptionAddOnAttributes = React.lazy(() =>
  import(
    "./components/SubscriptionAddOnAttributes/CreateSubscriptionAddOnAttributes"
  )
);

// Susbcription Plan
const CreateSubscriptionPlanAttributes = React.lazy(() =>
  import(
    "./components/SubscriptionPlanAttributes/CreateSubscriptionPlanAttributes"
  )
);

// Languages
const ListActiveLanguage = React.lazy(() =>
  import("./components/ManageLanguage/ListActiveLanguage/ListActiveLanguage")
);
const CreateNewLanguage = React.lazy(() =>
  import("./components/ManageLanguage/CreateNewLanguage/CreateNewLanguage")
);
const ViewLanguageDetail = React.lazy(() =>
  import("./components/ManageLanguage/ViewLanguageDetail/ViewLanguageDetail")
);

// Manage Data Stream components
const CreateNewDataStream = React.lazy(() =>
  import(
    "./components/ManageDataStream/CreateNewDataStream/CreateNewDataStream"
  )
);
const ListDataStream = React.lazy(() =>
  import("./components/ManageDataStream/ListDataStream/ListDataStream")
);
const ViewDataStream = React.lazy(() =>
  import("./components/ManageDataStream/ViewDataStream/ViewDataStream")
);
const ListFieldConfig = React.lazy(() =>
  import("./components/FieldConfiguration/ListFieldConfig")
);
const AddField = React.lazy(() =>
  import("./components/FieldConfiguration/AddField")
);
const FieldConfigJson = React.lazy(() =>
  import("./components/FieldConfiguration/FieldConfigJson")
);

// API CRUD components
const ListAPI = React.lazy(() =>
  import("./components/ManageApi/List/ListAddedApis")
);
const CreateNewApi = React.lazy(() =>
  import("./components/ManageApi/Create/CreateNewApi")
);
const CreateNewAuthApi = React.lazy(() =>
  import("./components/ManageApi/Create/CreateNewAuthApi")
);
const ViewAPI = React.lazy(() =>
  import("./components/ManageApi/View/ViewApiDetail")
);

// API Config CRUD components
const CreateApiConfig = React.lazy(() =>
  import("./components/ManageApiConfig/Create/CreateApiConfig")
);

// Logs
const PythonLogs = React.lazy(() => import("./components/Logs/PythonLogs"));

// profile-changepassword
// const ChangePassword = React.lazy(() => import("./components/Pages/ChangePassword/ChangePassword"));

// otp

const Otp = React.lazy(() => import("./components/Otp"));

const Root = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route path={`/`} element={<Auth />}>
              <Route index element={<AuthLogin />} />
              <Route path={`/authentication/login`} element={<AuthLogin />} />

              <Route path={`/Otp`} element={<Otp />} />

              <Route path={`/ForgotPassword`} element={<ForgotPassword />} />
              <Route path={`/ResetPassword`} element={<ResetPassword />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path={`/`} element={<IdleTimerProviderComponent><App /></IdleTimerProviderComponent>}>
                <Route>               
                  <Route path={`/dashboard`} element={<Dashboard />} />               
                </Route>

                {/* manage admin */}

                <Route>
                  <Route
                    path={`/ManageAdminUser/CreateUser`}
                    element={<CreateUser />}
                  />
                  <Route
                    path={`/ManageAdminUser/ListUser`}
                    element={<ListUser />}
                  />
                </Route>

                {/* Companies */}

                <Route>
                  <Route
                    path={`/ManageCompany/ListCompany`}
                    element={<ListCompany />}
                  />
                  <Route
                    path={`/ManageCompany/ViewCompany/:companyId`}
                    element={<ViewCompany />}
                  />

                  <Route
                    path={`/ManageCompany/CreateCompany`}
                    element={<CreateCompany />}
                  />
                  <Route
                    path={`/ManageCompany/CreateCompany`}
                    element={<CreateCompany />}
                  />
                  <Route
                    path={`/ManageCompany/CreateCompany/:companyId`}
                    element={<CreateCompany />}
                  />

                  <Route
                    path={`/ManageCompany/Configuration`}
                    element={<CreateApiConfig />}
                  />
                </Route>

                {/* manage subcription */}
                <Route>
                  <Route
                    path={`/ManageSubcription/ListActiveSubcription`}
                    element={<ListActiveSubcription />}
                  />
                  <Route
                    path={`/ManageSubcription/CreateNewSubcription`}
                    element={<CreateNewSubcription />}
                  />
                  <Route
                    path={`/ManageSubcription/View`}
                    element={<CreateNewSubcription />}
                  />
                  <Route
                    path={`/ManageSubcription/Update`}
                    element={<CreateNewSubcription />}
                  />
                </Route>

                {/* manage subscription plan */}
                <Route>
                  <Route
                    path={`/subscription-plan/list`}
                    element={<ListSubscriptionPlan />}
                  />
                  <Route
                    path={`/subscription-plan/create`}
                    element={<CreateNewSubscriptionPlan />}
                  />
                  <Route
                    path={`/subscription-plan/:subscriptionPlanId`}
                    element={<CreateNewSubscriptionPlan />}
                  />
                  <Route
                    path={`/subscription-plan/attribute/create`}
                    element={<CreateSubscriptionPlanAttributes />}
                  />
                  <Route
                    path={`/subscription-plan/attribute/:planAttributeId`}
                    element={<CreateSubscriptionPlanAttributes />}
                  />
                </Route>

                {/*subscription plan add-on*/}
                <Route>
                  <Route
                    path={`/addon-plan/list`}
                    element={<ListSubscriptionAddOnPlan />}
                  />
                  <Route
                    path={`/addon-plan/create`}
                    element={<CreateSubscriptionAddOnPlan />}
                  />
                  <Route
                    path={`/addon-plan/:subscriptionAddonPlanId`}
                    element={<CreateSubscriptionAddOnPlan />}
                  />
                  <Route
                    path={`/addon-plan/attribute/create`}
                    element={<CreateSubscriptionAddOnAttributes />}
                  />
                  <Route
                    path={`/addon-plan/attribute/:addonPlanId`}
                    element={<CreateSubscriptionAddOnAttributes />}
                  />
                </Route>

                {/*Attribute plan add-on*/}
                <Route>
                  <Route
                    path={`/addon-plan-attribute/list`}
                    element={<ListSubscriptionAddOnAttribute />}
                  />
                </Route>

                {/* Languages */}
                <Route>
                  <Route
                    path={`/Language/ListActiveLanguage`}
                    element={<ListActiveLanguage />}
                  />
                  <Route
                    path={`/Language/CreateNewLanguage`}
                    element={<CreateNewLanguage />}
                  />
                  <Route
                    path={`/Language/CreateNewLanguage/:languageId`}
                    element={<CreateNewLanguage />}
                  />
                  <Route
                    path={`/Language/ViewLanguageDetail/:languageId`}
                    element={<ViewLanguageDetail />}
                  />
                </Route>

                {/* Manage Data Stream */}
                <Route>
                  <Route
                    path={`/DataStream/ListDataStream`}
                    element={<ListDataStream />}
                  />
                  <Route
                    path={`/DataStream/CreateNewDataStream`}
                    element={<CreateNewDataStream />}
                  />
                  <Route
                    path={`/DataStream/CreateNewDataStream/:dataStreamId`}
                    element={<CreateNewDataStream />}
                  />
                  <Route
                    path={`/DataStream/ViewDataStream/:dataStreamId`}
                    element={<ViewDataStream />}
                  />
                  <Route
                    path={`/DataStream/FieldConfig/:companyId/:dataStreamConfigId`}
                    element={<FieldConfigJson />}
                  />
                  <Route
                    path={`/DataStream/FieldConfig/AddField`}
                    element={<AddField />}
                  />
                  <Route
                    path={`/DataStream/FieldConfig/EditField/:fieldId`}
                    element={<AddField />}
                  />
                  {/* <Route
                    path={`/DataStream/FieldConfig/:fieldId`}
                    element={<ViewField />}
                  /> */}
                </Route>

                {/* Manage api list */}
                <Route>
                  <Route path={`/API/ListAPI`} element={<ListAPI />} />
                  <Route
                    path={`/API/CreateNewApi`}
                    element={<CreateNewAuthApi />}
                  />
                  <Route
                    path={`/API/CreateNewApi/:apiId`}
                    element={<CreateNewAuthApi />}
                  />
                  <Route
                    path={`/API/CreateNewAuthApi`}
                    element={<CreateNewAuthApi />}
                  />
                  <Route
                    path={`/API/CreateNewAuthApi/:apiId`}
                    element={<CreateNewAuthApi />}
                  />
                  <Route path={`/API/ViewAPI/:apiId`} element={<ViewAPI />} />
                </Route>
                <Route>
                  <Route path={`/Logs/Python`} element={<PythonLogs />} />
                  <Route
                    path={`/Logs/Java`}
                    element={<JavaLogs />}
                  />
                </Route>
              </Route>
            </Route>
            {/* ........................................Errorpage............................................... */}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </React.Suspense>
        <ToastContainer />
      </BrowserRouter>
    </Fragment>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />); 
