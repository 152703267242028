import React, { Fragment } from "react";
import { Breadcrumb } from "react-bootstrap";
import "./Logs.scss";

const JavaLogs = () => {

  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Java Logs</h2>
          <Breadcrumb>
            <Breadcrumb.Item href="#">Logs</Breadcrumb.Item>
            <Breadcrumb.Item active>Java Logs</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
    </Fragment>
  );
};

export default JavaLogs;
