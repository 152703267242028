// import { Navigate } from 'react-router-dom';
// const ProtectedRoute = ({ children }) => {
//     const jwt_token = localStorage.getItem('token');

//     const user = jwt_token ;
//     if (!user) {
//         return <Navigate to={`${process.env.PUBLIC_URL}/authentication/login`} replace />;
//     }
//     return children;
// };
// export default ProtectedRoute;

import { Navigate, Outlet } from 'react-router-dom'
const ProtectedRoute = () => {
    const jwt_token = localStorage.getItem('token');

    // console.log(jwt_token)
    // "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbkBhaWNjLmNvbSIsInNjb3BlcyI6IlJPTEVfU1VQRVJBRE1JTiIsImlhdCI6MTY2ODM5OTE1MywiZXhwIjoxNjY4NDE3MTUzfQ.HbZxp4r76IDWWJG2e0qKYmwtHV-iO66dMn_GD1sTJYB6SCmpjaTkwKj5kFXqJa1yYV7biOknKsPrl7H4XNBzLg"
    let auth = { 'token': jwt_token }
    return (
        auth.token ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/authentication/login`} />
    )
}
export default ProtectedRoute;